import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";

// Components
import Navbar from "./components/Navbar";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";

// Pages
// import Home from "./pages/Home";
import NewHome from "./pages/NewHome";
// import DiscoverDashboard from "./pages/DiscoverDashboard";
// import SignUpDiscover from "./pages/SignUpDiscover";
// import DiscoverHome from "./pages/DiscoverHome";
// import LoginDiscover from "./pages/LoginDiscover";
// import Personal from "./pages/Personal";
// import Relationship from "./pages/Relationship";
// import Career from "./pages/Career";
import About from "./pages/About";
// import ComingSoon from "./components/ComingSoon";
// import SharingLink from "./pages/SharingLink";
// import ShareCard from "./pages/ShareCard";
import WellnessDashboard from "./pages/WellnessDashboard";
import WellnessHome from "./pages/WellnessHome";
import SignUpWellness from "./pages/SignUpWellness";
import LoginWellness from "./pages/LoginWellness";
import Welcome from "./pages/WellnessGettingStarted/Welcome";
import SignUpPersonalDetails from "./pages/SignUpPersonalDetails";
import {
  CheckinYouth,
  WellnessGettingStarted,
} from "./pages/WellnessGettingStarted";
import CheckinNew from "./pages/WellnessGettingStarted/CheckInNew.jsx";
import ScheduleButton from "./components/ScheduleButton.js";
import WellnessGettingStartedEncouraged from "./pages/WellnessGettingStartedEncouraged";
import WellnessTest from "./pages/WellnessTest";
import WellnessTestNew from "./pages/WellnessTest/WellnessTestNew";
import PersonalityTest from "./pages/PersonalityTest";
import WellnessStoryIntro from "./pages/WellnessStoryIntro";
import WellnessDayOne from "./pages/WellnessDayOne";
import WellnessStoryTest from "./pages/WellnessStoryTest";
import WellnessStoryTimeline from "./pages/WellnessStoryTimeline";
import StoryExperience from "./pages/Wellness/StoryExperience";
import PeriodTracker from "./pages/PeriodTracker/index";
import IsraelHome from "./pages/Israel/index";
import WellnessHomeNew from "./pages/WellnessHomeNew.jsx";
import WellnessHomeOld from "./pages/WellnessHomeOld.jsx";
import ReferralPage from "./pages/ReferralPage.jsx";
import AccountPage from "./pages/Account/AccountPage.jsx";
import PageNotFound from "./pages/PageNotFound";
import EditStoryExperience from "./pages/Wellness/EditStoryExperience";
import TestDetails from "./pages/Wellness/TestDetails";
import PhysicalCheckIn from "./pages/PhysicalHealthDashboard/PhysicalCheckin.jsx";

// Theme
import { ThemeContext } from "./contexts/ThemeContext";
import ThemeToggleButton from "./components/ThemeToggleButton";
import { useResponsive } from "./tools/device.js";
import useAnalytics from "./hooks/useAnalytics.js";
import "./styles/styles.scss";
import BlueberryTallyPage from "./pages/BlueberrySignup/BlueberrySignup.jsx";
import BlueberryShortTallyPage from "./pages/BlueberrySignup/BlueberryShortSignup.jsx";
import AllAboutKidsTallyPage from "./pages/BlueberrySignup/AllAboutKidsSignup.jsx";
import OneFamilyPediatricsTallyPage from "./pages/BlueberrySignup/OneFamilyPediatricsSignup.jsx";
import TherapistDetail from "./components/Referrals/ReferralCard/IndividualTherapistDetail.jsx";
import ProviderTallyPage from "./pages/BlueberrySignup/ProviderSignup.jsx";

const AppLayout = () => {
  const location = useLocation();
  const isHome = location.pathname === "/" ? true : false;
  const [colorMode, setColorMode] = useState("BlueRed");
  useAnalytics();
  const isMobile = useResponsive()?.isMobile;

  return (
    <>
      <ThemeContext.Provider value={{ colorMode, setColorMode }}>
        <div className={`app theme-${colorMode}`}>
          {/* {!isMobile ? <Navbar /> : <Navbar />} */}
          {isMobile ? <MobileNavbar /> : <Navbar />}
          <Outlet />
          {!isHome ? <Footer /> : <Footer />}
          <ThemeToggleButton />
        </div>
      </ThemeContext.Provider>
    </>
  );
};

const partners = [
  { name: "All About Kids", tallyId: "3yEaox", path: "AllAboutKids" },
  {
    name: "Valley Pediatrics MD",
    tallyId: "woV2g5",
    path: "ValleyPediatricsMD",
  },
  {
    name: "One Family Pediatrics",
    tallyId: "nrrDBR",
    path: "OneFamilyPediatrics",
  },
  ,
];

const ProviderPartnerComponent = (tallyId) => (
  <ProviderTallyPage tallyId={tallyId} />
);

const buildPartnerRoutes = () => {
  // Flatten manually by using reduce to combine all routes into a single array
  return partners.reduce((acc, { path, tallyId }) => {
    const routes = [
      {
        path: `/${path}`,
        element: ProviderPartnerComponent(tallyId),
      },
      {
        path: `/partner/${path}`,
        element: ProviderPartnerComponent(tallyId),
      },
      {
        path: `/partners/${path}`,
        element: ProviderPartnerComponent(tallyId),
      },
      {
        path: `/pediatrician/${path}`,
        element: ProviderPartnerComponent(tallyId),
      },
    ];
    return [...acc, ...routes];
  }, []);
};

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      ...buildPartnerRoutes(), // Spread the built routes into the children array.
      {
        path: "/",
        // element: <NewHome />,
        element: <WellnessHome />,
      },
      {
        path: "/partners/blueberry",
        element: <BlueberryShortTallyPage />,
      },
      {
        path: "/partner/blueberry",
        element: <BlueberryShortTallyPage />,
      },
      {
        path: "/blueberry",
        element: <BlueberryTallyPage />,
      },

      {
        path: "wellness",
        element: <WellnessHome />,
      },
      {
        path: "wellness/new",
        element: <WellnessHomeNew />,
      },
      {
        path: "wellness/old",
        element: <WellnessHomeOld />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "wellness/dashboard",
        element: <WellnessDashboard />,
      },
      {
        path: "wellness/signup/personal-details",
        element: <SignUpPersonalDetails />,
      },
      {
        path: "wellness/account",
        element: <AccountPage />,
      },
      {
        path: "wellness/welcome",
        element: <Welcome />,
      },
      {
        path: "wellness/check-in",
        // element: <WellnessGettingStarted />,
        element: <CheckinNew />,
      },
      {
        path: "wellness/check-in-youth",
        element: <CheckinYouth />,
      },
      {
        path: "wellness/schedule-next-checkup",
        element: <ScheduleButton />,
      },
      {
        path: "wellness/getting-started-essentials",
        element: <WellnessGettingStarted />,
      },
      {
        path: "wellness/getting-started-encouraged",
        element: <WellnessGettingStartedEncouraged />,
      },
      {
        path: "wellness/test/:testId/new",
        element: <WellnessTestNew />,
      },
      {
        path: "wellness/test/:testId",
        element: <WellnessTest />,
      },
      {
        path: "personality/test/:testId",
        element: <PersonalityTest />,
      },
      {
        path: "wellness/test-details/:testId",
        element: <TestDetails />,
      },
      {
        path: "wellness/story-test/:testId",
        element: <WellnessStoryTest />,
      },
      {
        path: "wellness/signup",
        element: <SignUpWellness />,
      },
      {
        path: "wellness/login",
        element: <LoginWellness />,
      },
      {
        path: "wellness/story-intro",
        element: <WellnessStoryIntro />,
      },
      {
        path: "wellness/story-day-one",
        element: <WellnessDayOne />,
      },
      {
        path: "wellness/story-timeline",
        element: <WellnessStoryTimeline />,
      },
      {
        path: "wellness/story-experience-form",
        element: <StoryExperience />,
      },
      {
        path: "wellness/story-experience-form/:storyId",
        element: <EditStoryExperience />,
      },
      {
        path: "wellness/period-tracker",
        element: <PeriodTracker />,
      },
      {
        path: "wellness/period-tracker",
        element: <PeriodTracker />,
      },
      {
        path: "israel",
        element: <IsraelHome />,
      },
      {
        path: "wellness/physical-check-in",
        element: <PhysicalCheckIn />,
      },
      {
        path: "wellness/physical-check-in-youth",
        element: <CheckinYouth />,
      },
      {
        path: "wellness/check-in-new",
        element: <CheckinNew />,
      },
      {
        path: "wellness/find-care",
        element: <ReferralPage />,
      },
      {
        path: "wellness/find-therapist",
        element: <ReferralPage />,
      },
      {
        path: "therapists/:therapistId",
        element: <TherapistDetail />,
      },

      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
